import React from 'react';
import styled from 'styled-components';
import fire from '../cursors/fire_emoji.png';

const StyledImg = styled.img`
    cursor: url(${fire}), auto !important;
`;

const StyledVideo = styled.video`
    cursor: url(${fire}), auto !important;
`;

export default function FramedImg({ src, alt, text, onClick, video }: { src: string, alt: string, text: JSX.Element, onClick: (event: React.MouseEvent) => void, video?: boolean }) {
    const img = video ?
        <StyledVideo className="mw-100 w-100" onClick={onClick} autoPlay loop muted>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </StyledVideo> :
        <StyledImg className="mw-100 w-100" src={src} alt={alt} onClick={onClick} />
    return (
        <figure className="mw-100 m-0 d-flex align-content-end flex-wrap">
            <figcaption className="text-left w-100">{text}</figcaption>
            {img}
        </figure>
    )
}
