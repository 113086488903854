import React from 'react';
import styled from 'styled-components';
import amoreira1 from './images/amoreira1.jpg';
import amoreira3 from './images/amoreira3.jpg';
import amoreira_web_1 from './images/amoreira_web_1.jpg';
import amoreira_web_2 from './images/amoreira_web_2.jpg';
import amoreira_web_3 from './images/amoreira_web_3.jpg';
import amoreira_web_4 from './images/amoreira_web_4.jpg';
import dau1 from './images/dau1.jpg';
import dau2 from './images/dau2.jpg';
import elllindar_gif from './images/elllindar.gif';
import keraco1 from './images/keraco1.jpg';
import keraco2 from './images/keraco2.jpg';
import keraco3 from './images/keraco3.jpg';
import natwins1 from './images/natwins1.jpg';
import natwins2 from './images/natwins2.jpg';
import natwins3 from './images/natwins3.jpg';
import tarallara1 from './images/tarallara1.jpg';
import tarallara2 from './images/tarallara2.jpg';
import tarallara4 from './images/tarallara4.jpg';
import botijo from './images/botijo_serp.jpg';
import mydrap1_1 from './images/mydrap1_1.jpg';
import mydrap2_1 from './images/mydrap2_1.jpg';
import mydrap4_1 from './images/mydrap4_1.jpg';
import clapmag from './images/clapmag.gif';
import famatel1 from './images/famatel1.jpg';
import famatel2 from './images/famatel2.jpg';
import famatel3 from './images/famatel3.jpeg';
import famatel4 from './images/famatel4.jpg';
import famatel5 from './images/famatel5.jpg';
import famatel6 from './images/famatel6.jpeg';
import homs from './images/homs.gif';
import nature1 from './images/nature1.jpg';
import nature2 from './images/nature2.jpeg';
import nature3 from './images/nature3.jpeg';
import nature4 from './images/nature4.jpeg';
import iblu1 from './images/iblu1.jpg';
import iblu2 from './images/iblu2.jpg';
import iblu3 from './images/iblu3.gif';
import iblu4 from './images/iblu4.jpg';
import iblu5 from './images/iblu5.jpg';
import iblu6 from './images/iblu6.jpg';
import iblu7 from './images/iblu7.jpg';
import barca1 from './images/barca1.gif';
import barca2 from './images/barca2.jpg';
import barca3 from './images/barca3.gif';
import barca4 from './images/barca4.jpg';
import vera from './images/vera.mp4';
import planeta from './images/planeta.mp4';
import diamant from './cursors/diamant_emoji.png';

export interface Project {
    color: string
    text: JSX.Element,
    images: { src: string, alt: string, video?: boolean }[],
    delay: number,
}

export interface Content {
    main: JSX.Element
    sidebar: JSX.Element
    projects: Project[]
}

export const StyledLink = styled.a`
    text-decoration: none;
    color: #0067FF;
    cursor: url(${diamant}), auto;
    &:hover {
        text-decoration: underline;
        color: #0067FF;
    }
    @media (max-width: 480px) {
        text-decoration: underline;
    }
`;

export const StyledTitle = styled.h1`
    font-weight: 700;
    font-size:   1rem;
    line-height: 1.5rem;
    margin: 0;
    @media (max-width: 480px) {
        font-size: 0.7rem;
        line-height: 0.9rem;
    }
    color: red;
`;

export const StyledText = styled.p`
    font-weight: 400;
    font-size:   1rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    @media (max-width: 480px) {
        font-size: 0.7rem;
        line-height: 0.9rem;
    }
    color: red;
`;

export const NoMarginText = styled(StyledText)`
    margin: 0;
`;

const linkNomon = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/">Nomon Design</StyledLink>;
const linkSumma = <StyledLink target="_blank" rel="noopener noreferrer" href="https://summa.es/">Summa Branding</StyledLink>;
const linkPhotos = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.lomography.es/homes/alittlepea">analogic photography</StyledLink>;
const linkMail = <StyledLink target="_blank" rel="noopener noreferrer" href="mailto:helenallop@gmail.com">helenallop@gmail.com</StyledLink>;
const linkIn = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/helenallop/">In</StyledLink>;
const linkBe = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.behance.net/helenallop">Be</StyledLink>;
const linkCv = <StyledLink target="_blank" rel="noopener noreferrer" href="./helena_llop_cv.pdf">Cv</StyledLink>;

const linkVera = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.somvera.cat/">Catalan telecommunications company</StyledLink>;
const linkSummaVera = <StyledLink target="_blank" rel="noopener noreferrer" href="https://summa.es/all-projects/vera/">Summa Branding</StyledLink>;
const linkPlaneta = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.planetadelibros.com/">Grupo Planeta's reading community platform</StyledLink>;
const linkSummaPlaneta = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/p/Cv_63FZIztw/?img_index=1">Summa Branding</StyledLink>;

const linkOptica = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/ibluoptica/">Optician store in Andorra</StyledLink>;
const linkSummaFCB = <StyledLink target="_blank" rel="noopener noreferrer" href="https://summa.es/all-projects/fc-barcelona/">Summa Branding</StyledLink>;
const linkHoms = <StyledLink target="_blank" rel="noopener noreferrer" href="https://homsrentals.com/">Work tools rental company</StyledLink>;
const linkFamatel = <StyledLink target="_blank" rel="noopener noreferrer" href="https://famatel.com/">Electrical products company</StyledLink>;
const linkFamatelNomon = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/proyectos/famatel-estrategia-branding/">Nomon Design</StyledLink>;
const linkNatureNomon = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/proyectos/klein-comunicacion-nature/">Nomon Design</StyledLink>;
const linkMoreira1 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/en/work/a-moreira-corporate-branding/">Nomon Design</StyledLink>;
const linkKerako1 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.keraco.com/es/">website design for a Technical ceramics company</StyledLink>;
const linkKerako2 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/proyectos/keraco-branding-corporativo/">Nomon Design</StyledLink>;
const linkNatwins1 = <StyledLink target="_blank" rel="noopener noreferrer" href="http://www.natwins.com/">Girona's cookies brand</StyledLink>;
const linkNatwins2 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/en/work/natwins-diseno-packaging/">Nomon Design</StyledLink>;
const linkDau1 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://fundaciodau.org/ca/">non-profit mental health foundation</StyledLink>;
const linkDau2 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/noticias/colaboracion-blanquerna-comunicacion-entidades/">Nomon Design</StyledLink>;
const linkLlindar = <StyledLink target="_blank" rel="noopener noreferrer" href="http://www.elllindar.org/">New opportunities school foundation</StyledLink>;
const linkTrallara1 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.elisava.net/projectes/tarallara">Elisava</StyledLink>;
const linkAndres = <StyledLink target="_blank" rel="noopener noreferrer" href="https://www.behance.net/Andres_Villanueva">Andrés Villanueva</StyledLink>;

const linkMyDrap1 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://mydrap.com/es">fabric and finishing manufacturing company</StyledLink>;
const linkMyDrap2 = <StyledLink target="_blank" rel="noopener noreferrer" href="https://nomondesign.com/proyectos/my-drap-producto-2019">Nomon Design</StyledLink>;



const content: Content = {
    sidebar: (<div>
        <StyledTitle>HE<br />LLO</StyledTitle>
        <NoMarginText><br />{linkIn}<br />{linkBe}<br />{linkCv}</NoMarginText>
    </div>),
    main: (<StyledText>I‘m Helena Llop, an art director, graphic designer, specialized in branding and architect from Barcelona.
        After graduating in architecture, I decided pursuing my true passion and studying Graphic Design.
        <br />
        I've worked at {linkNomon} as a designer, art director and motion grapher,
        undertaking projects of brand identity, packaging, web, communication, space design and photography.
        Now, I work at {linkSumma} where, as a Brand Art Director, I create,
        develop and curate brands for clients around the world.
        <br />
        In my free time I like to sew, do pottery and {linkPhotos}.
        I'm open to projects and collaborations of any kind. You can contact me at {linkMail}.</StyledText>),
    projects: [
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Visual identity for a {linkVera}. Developed at {linkSummaVera}.</StyledText></div>),
            images: [
                { src: vera, alt: "", video: true },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Platform for {linkPlaneta}. Developed at {linkSummaPlaneta}.</StyledText></div>),
            images: [
                { src: planeta, alt: "", video: true },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Visual identity for an {linkOptica}. Developed at {linkSumma}.</StyledText></div>),
            images: [
                { src: iblu1, alt: "" },
                { src: iblu2, alt: "" },
                { src: iblu3, alt: "" },
                { src: iblu4, alt: "" },
                { src: iblu5, alt: "" },
                { src: iblu6, alt: "" },
                { src: iblu7, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Expansion of visual brand system for FC Barcelona. Developed at {linkSummaFCB}.</StyledText></div>),
            images: [
                { src: barca1, alt: "" },
                { src: barca2, alt: "" },
                { src: barca3, alt: "" },
                { src: barca4, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Visual identity for a {linkHoms}. Developed at {linkSumma}.</StyledText></div>),
            images: [
                { src: homs, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Visual identity, packaging and web for an {linkFamatel}. Developed at {linkFamatelNomon}.</StyledText></div>),
            images: [
                { src: famatel1, alt: "" },
                { src: famatel2, alt: "" },
                { src: famatel3, alt: "" },
                { src: famatel4, alt: "" },
                { src: famatel5, alt: "" },
                { src: famatel6, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Visual identity and website design for a marketing consultant. Developed at {linkMoreira1}.</StyledText>
            </div>),
            images: [
                { src: amoreira1, alt: "" },
                { src: amoreira3, alt: "" },
                { src: amoreira_web_1, alt: "" },
                { src: amoreira_web_2, alt: "" },
                { src: amoreira_web_3, alt: "" },
                { src: amoreira_web_4, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Visual identity, art direction and {linkKerako1}. Developed at {linkKerako2}.</StyledText>
            </div>),
            images: [
                { src: keraco1, alt: "" },
                { src: keraco2, alt: "" },
                { src: keraco3, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div><StyledText>Logotype for an ecological wood doors brand. Developed at {linkNatureNomon}.</StyledText></div>),
            images: [
                { src: nature1, alt: "" },
                { src: nature2, alt: "" },
                { src: nature3, alt: "" },
                { src: nature4, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Packaging and graphic design for {linkNatwins1}. Developed at {linkNatwins2}.</StyledText>
            </div>),
            images: [
                { src: natwins1, alt: "" },
                { src: natwins2, alt: "" },
                { src: natwins3, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Internal collaborative graphic action for a {linkDau1}. Developed at {linkDau2}.</StyledText>
            </div>),
            images: [
                { src: dau1, alt: "" },
                { src: dau2, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Graphic identity, copy and motion design for a {linkLlindar}. Developed at {linkNomon}. </StyledText>
            </div>),
            images: [
                { src: elllindar_gif, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>
                    Art direction and pattern design for a {linkMyDrap1}.
                    Developed at {linkMyDrap2}.
                </StyledText>
            </div>),
            images: [
                { src: mydrap1_1, alt: "" },
                { src: mydrap2_1, alt: "" },
                { src: mydrap4_1, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Visual Identity, naming, digital & motion design of the Music Museum of Barcelona. Developed at {linkTrallara1}. With {linkAndres}.</StyledText>
            </div>),
            images: [
                { src: tarallara1, alt: "" },
                { src: tarallara2, alt: "" },
                { src: tarallara4, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Editorial design for a fictitious magazine, about cinema and music. With {linkAndres}. </StyledText>
            </div>),
            images: [
                { src: clapmag, alt: "" },
            ],
            delay: 2000,
        },
        {
            color: "#FFF9ED",
            text: (<div>
                <StyledText>Illustrated handmade ceramic bottle. </StyledText>
            </div>),
            images: [
                { src: botijo, alt: "" },
            ],
            delay: 2000,
        },
    ]
}

export default content;