import React from 'react';
import './App.scss';
import styled, { ThemeProvider } from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import FramedImg from './components/FramedImg';
import content, { StyledTitle } from './content';
import patates from './cursors/patates_emoji.png';

const StyledContainer = styled(Container)`
    min-width: 100vw;
    min-height: 100vh;
    background-color: ${props => props.theme.main};
    cursor: url(${patates}), auto;
`;

const StyledFooter = styled(StyledTitle)`
    line-height: 1.2rem;
    @media (max-width: 480px) {
        line-height: 0.6rem;
    }
`;

const FullHeightRow = styled(Row)`
    min-height: 100vh;
`;

const VariableWidthCarousel = styled(Carousel)`
    width: initial !important;
    .carousel-inner {
        width: initial !important;
    }
`;

const VariableWidthItem = styled(Carousel.Item)`
    width: initial;
`;

function App() {
    const [state, setState] = React.useState({ idx: 0 });
    const onClick = () => {
        const nextIdx = (state.idx + 1) % content.projects.length;
        setState({ idx: nextIdx });
    };

    const curProj = content.projects[state.idx]
    const carousel = (
        // key set to make sure carousel is different for each project
        <VariableWidthCarousel key={state.idx} controls={false} slide={false} indicators={false} pause={false} interval={curProj.delay}>
            {curProj.images && curProj.images.map((img, idx) => (
                <VariableWidthItem key={img.alt + idx} className="float-right mr-0">
                    <FramedImg onClick={onClick} text={curProj.text} {...img} />
                </VariableWidthItem>
            ))}
        </VariableWidthCarousel>
    );

    // preload next img to avoid loading time after click
    // but do not display it (d-none)
    const nextImgs = content.projects[(state.idx + 1) % content.projects.length].images;
    const preload = (<div className="d-none">
        {nextImgs && nextImgs.length > 0 &&
            <FramedImg onClick={onClick} text={curProj.text} {...nextImgs[0]} />
        }
    </div>);

    const pageCount = (<StyledFooter className="my-auto">{state.idx + 1}/{content.projects.length}</StyledFooter>);

    return (
        <ThemeProvider theme={{ main: curProj.color }}>
            <StyledContainer fluid>
                <FullHeightRow className="justify-content-between">
                    <Col xs={1} xl={1} className="py-2 px-2 vw-100">{content.sidebar}</Col>
                    <Col xs={11} xl={5} className="py-2 px-2 vw-100">{content.main}</Col>
                    <Col xs={1} xl={1} className="py-2 px-2 vw-100 align-self-end text-right">{pageCount}</Col>
                    <Col xs={11} xl={5} className="py-2 px-2 align-self-end">{carousel}</Col>
                    {preload}
                </FullHeightRow>
            </StyledContainer>
        </ThemeProvider>
    );
}

export default App;
